<template>
    <div>
        <Table
            :isLoading="isLoading"
            :columnConfig="tableColumnConfig"
            :rows="tableShowData"
            :isRecursive="true"
            :isUseCard="false"
            :isFocusRow="true"
            :toolbox="['columnSetting', 'zooming']"
            :sortDefault="{ sort: 'desc', sortBy: 'normalTotalAmount' }"
            :isFixedHeader="true"
            :hiddenColumnDefault="hiddenCols"
            :columnsMode="columnsMode"
        >
            <template v-slot:name="{ isHeader, row }">
                <div v-if="!isHeader" v-html="row.name" />
            </template>
        </Table>
    </div>
</template>

<script>
import _ from 'lodash'
import Table from '@/components/Table.vue'
import { underlingColumns } from '@/assets/javascripts/commission/setting'

export default {
    name: 'UnderlingTable',
    components: {
        Table
    },
    props: {
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        commissionList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        filterLog: {
            type: Object,
            default: function () {
                return {}
            }
        },
        headerData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        transformDetail: function (rows) {
            return rows.reduce((memo, row) => {
                const manCode = row.manCode
                const commission = this.commissionList[manCode]
                row.relativeDepth = row.isSelf ? '本人' : row.relativeDepth

                if (row.sub) {
                    if (row?.visible === false) {
                        memo = [...memo, ...this.transformDetail(row.sub)]
                    } else {
                        row._detail = this.transformDetail(row.sub)
                        this.getSumRow(row, row._detail)
                        memo.push(_.omit(row, 'sub'))
                    }
                } else {
                    /* end node */

                    if (row.visible === false) {
                        return memo
                    }
                    // salesName用於轉過HTML的欄位
                    row.name = row.name || '-'
                    row.relativeDepth = row.relativeDepth ?? '-'
                    row.bgName = row.bgName || '-'

                    commission?.forEach(function (data) {
                        if (data.type === '一般佣金') {
                            row.normalSaleTotal = data.saleTotal ?? '-'
                            row.normalOrganizationTotal =
                                data.organizationTotal ?? '-'
                            row.normalOtherTotal = data.otherTotal ?? '-'
                            row.normalTotalAmount = data.totalAmount ?? '-'
                        } else {
                            row.yearBonusSaleTotal = data.saleTotal ?? '-'
                            row.yearBonusOrganizationTotal =
                                data.organizationTotal ?? '-'
                            row.yearBonusOtherTotal = data.otherTotal ?? '-'
                            row.yearBonusTotalAmount = data.totalAmount ?? '-'
                        }
                    })

                    // memo.push(row)
                    memo.unshift(row)
                }
                return memo
            }, [])
        },
        getSumRow: function (row) {
            const money = [
                'yearBonusSaleTotal',
                'yearBonusOrganizationTotal',
                'yearBonusOtherTotal',
                'yearBonusTotalAmount',
                'normalSaleTotal',
                'normalOrganizationTotal',
                'normalOtherTotal',
                'normalTotalAmount'
            ]
            //組織佣金合計
            // money.forEach((key) => {
            //     row[key] = _.sumBy(detail, (o) => {
            //         if (typeof o[key] !== 'number') o[key] = null
            //         return o[key]
            //     })
            // })

            money.forEach((key) => {
                row[key] = { value: ' ', unit: '' }
            })
            // salesName用於轉過HTML的欄位
            row.name = `${row.name}&nbsp;&nbsp;&nbsp;${row.groupTitle}`
            row.relativeDepth = row.relativeDepth ?? '-'
            row.bgName = row.bgName || '-'

            return row
        },
        splitCommission: function () {}
    },
    computed: {
        tableShowData: function () {
            if (this.listData?.length === 0) return []
            let dataset = _.cloneDeep(this.listData)
            dataset = this.transformDetail(dataset)
            // this.$reloadTips() // 2022/11/03 累積扣款問題暫解
            return dataset
        },
        tableColumnConfig: function () {
            return this.headerData?.hasYearEndBonus
                ? underlingColumns
                : _.omit(underlingColumns, [
                      'yearBonusSaleTotal',
                      'yearBonusOrganizationTotal',
                      'yearBonusOtherTotal',
                      'yearBonusTotalAmount'
                  ])
        },
        // 2022/11/03 累積扣款問題暫解
        isCeo: function () {
            return this.filterLog.role?.value === 'ceo'
        },
        hiddenCols: function () {
            return this.isCeo ? ['relativeDepth'] : []
        },
        columnsMode: function () {
            return this.isCeo ? 'ceo' : null
        }
    }
}
</script>

<style lang="scss" scoped>
:deep(.table) {
    @for $i from 0 through 15 {
        .deep-#{$i} .control-col {
            padding-left: 0;
        }
    }
}
</style>
